// Generated by Framer (de9b242)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["QcLDRZ8kK", "UBqicH6kV", "j5CLvpiSM"];

const variantClassNames = {j5CLvpiSM: "framer-v-121p7d5", QcLDRZ8kK: "framer-v-1r6zsgc", UBqicH6kV: "framer-v-flr6cv"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {"Variant 1": "QcLDRZ8kK", "Variant 2": "UBqicH6kV", "Variant 3": "j5CLvpiSM"};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "tween"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "QcLDRZ8kK", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "QcLDRZ8kK", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppear2eadg8 = activeVariantCallback(async (...args) => {
setVariant("j5CLvpiSM")
})

const onAppear2ii5ap = activeVariantCallback(async (...args) => {
setVariant("QcLDRZ8kK")
})

useOnVariantChange(baseVariant, {j5CLvpiSM: onAppear2ii5ap, UBqicH6kV: onAppear2eadg8})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-RkxSs", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-1r6zsgc", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"QcLDRZ8kK"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({j5CLvpiSM: {"data-framer-name": "Variant 3", "data-highlight": true}, UBqicH6kV: {"data-framer-name": "Variant 2", "data-highlight": true}}, baseVariant, gestureVariant)}><motion.div className={"framer-1eze4v4"} layoutDependency={layoutDependency} layoutId={"KdRBGbi6h"} style={{backgroundColor: "rgb(0, 255, 166)", borderBottomLeftRadius: 287, borderBottomRightRadius: 287, borderTopLeftRadius: 287, borderTopRightRadius: 287, opacity: 1}} transition={transition} variants={{UBqicH6kV: {opacity: 0}}}/><motion.div className={"framer-4o0zwv"} layoutDependency={layoutDependency} layoutId={"GewjBxEmP"} style={{backgroundColor: "rgb(0, 255, 166)", borderBottomLeftRadius: 287, borderBottomRightRadius: 287, borderTopLeftRadius: 287, borderTopRightRadius: 287}} transition={transition}/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-RkxSs [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-RkxSs .framer-9t4uxg { display: block; }", ".framer-RkxSs .framer-1r6zsgc { height: 14px; overflow: hidden; position: relative; width: 14px; }", ".framer-RkxSs .framer-1eze4v4 { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 6px); left: calc(50.00000000000002% - 6px / 2); overflow: hidden; position: absolute; top: calc(50.00000000000002% - 6px / 2); width: 6px; will-change: transform; }", ".framer-RkxSs .framer-4o0zwv { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 0px); left: calc(50.00000000000002% - 0px / 2); overflow: hidden; position: absolute; top: calc(50.00000000000002% - 0px / 2); width: 0px; will-change: transform; }", ".framer-RkxSs.framer-v-flr6cv .framer-1r6zsgc, .framer-RkxSs.framer-v-121p7d5 .framer-1r6zsgc { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 14px); }", ".framer-RkxSs.framer-v-flr6cv .framer-1eze4v4 { height: var(--framer-aspect-ratio-supported, 14px); left: 0px; right: 0px; top: 0px; width: unset; }", ".framer-RkxSs.framer-v-flr6cv .framer-4o0zwv { height: var(--framer-aspect-ratio-supported, 6px); left: calc(50.00000000000002% - 6px / 2); top: calc(50.00000000000002% - 6px / 2); width: 6px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 14
 * @framerIntrinsicWidth 14
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"UBqicH6kV":{"layout":["fixed","fixed"]},"j5CLvpiSM":{"layout":["fixed","fixed"]}}}
 */
const FramerFjSZaF_Ul: React.ComponentType<Props> = withCSS(Component, css, "framer-RkxSs") as typeof Component;
export default FramerFjSZaF_Ul;

FramerFjSZaF_Ul.displayName = "availability";

FramerFjSZaF_Ul.defaultProps = {height: 14, width: 14};

addPropertyControls(FramerFjSZaF_Ul, {variant: {options: ["QcLDRZ8kK", "UBqicH6kV", "j5CLvpiSM"], optionTitles: ["Variant 1", "Variant 2", "Variant 3"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerFjSZaF_Ul, [])